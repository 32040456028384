@tailwind base;
@tailwind components;
@tailwind utilities;
/* @import url('https://fonts.cdnfonts.com/css/helvetica'); */

body {
  font-family: 'helvetica';
  overflow-x: hidden;
  margin: 0;
  padding: 0;
  font-size: small;
}

*::-webkit-scrollbar {
  width: 5px;
}
*::-webkit-scrollbar-track {
  background-color: lightgrey;
}
*::-webkit-scrollbar-thumb {
  background-color: #2684fe;
}

.customer-landing-page a {
  color: #2684fe !important;
}

.custom-dropdown-toggle {
  height: 50px;
  background: transparent;
  border: 0px;
  color: white;
  text-align: left;
  cursor: pointer;
}

.custom-dropdown-toggle .super {
  font-size: 12px;
  line-height: 12px;
  font-weight: 600;
  display: block;
  margin-bottom: 4px;
}

.custom-dropdown-menu {
  display: none;
  margin-top: 3px !important;
  position: absolute;
  margin-left: -35px !important;
  margin-right: -35px !important;
}

.custom-dropdown-menu.show {
  display: block;
  position: absolute;
  font-size: 16px;
  line-height: 24px;
  width: 231px;
  background: white;
  border: 1px solid #fff;
  border-radius: 10px;
  border: 1px solid #ccc;
  color: black !important;
  padding-left: 31px;
  padding-right: 31px;
  padding-top: 15px;
  padding-bottom: 22px;
  z-index: 5000;
}

/* Avatar */
.avatar {
  display: inline-block;
  margin-bottom: -0.7rem;
  height: 3rem;
  width: 3rem;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  border-radius: 50%;
}

.avatar img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.rounded-circle {
  border-radius: 50% !important;
}

.btn.focus,
.btn:focus {
  outline: 0;
  box-shadow: none;
}

.avatar-nav {
  margin-right: 0.6rem;
  height: 2.2rem;
  width: 2.2rem;
}

.dropdown {
  position: relative;
  display: inline-block;
  vertical-align: middle;
}

.custom-dropdown-menu.show::before {
  content: ' ';
  display: block;
  background: white;
  position: relative;
  left: 135px;
  top: -22px;
  width: 20px;
  height: 7px;
  clip-path: polygon(50% 0%, 0% 100%, 100% 100%);
}

.custom-dropdown-menu > * {
  display: block;
  margin-bottom: 10px;
}

.dropdown-menu.show {
  width: 230px;
}

.modal-body-scrollable {
  max-height: 400px; /* Adjust this value based on your needs */
  overflow-y: auto;
}

div.big-select-picker {
  max-width: 400px !important;
}

.big-select-picker .dropdown-menu.show {
  width: 100%;
}

.custom-dropdown-item.wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  margin: 0;
}

.custom-dropdown-item.wrapper a {
  margin-bottom: 10px;
}

.custom-dropdown-item.wrapper .btn {
  color: white !important;
}

.blue-link {
  color: #2684fe !important;
}

span.dropdown-item {
  color: #2684fe !important;
}

.btn {
  height: 36px;
  color: white !important;
}

#mask_container label {
  margin-left: 1rem;
  margin-top: 0.25rem;
}

input[type='checkbox']:not(.toggle) {
  width: 24px;
  height: 24px;
  background-color: white;
  border-image: none;
  border-image-width: 0px;
  border: 1px solid #c4c4c4 !important;
  border-radius: 5px;
  /* // border-radius: 10px; */
  appearance: none;
}

input[type='checkbox']:not(.toggle):checked {
  width: 24px;
  height: 24px;
  background-color: #2684fe;
  border-image: none;
  border-image-width: 0px;
  border: 1px solid #c4c4c4 !important;
  border-radius: 5px;
  /* // border-radius: 10px; */
  appearance: none;
}

input[type='checkbox']:not(.toggle):checked::after {
  content: ' ';
  display: block;
  position: relative;
  left: 8px;
  top: 2px;
  width: 7px;
  height: 14px;
  border: solid white;
  border-width: 0 3px 3px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}

select:not(.filter-accordion select) {
  border-radius: 10px;
  border: 0px;
  padding-left: 6px;
  height: 38px;
  appearance: none;
  background-color: #cee2fd;
  background-image: url('./../public./../public/img/down_arrow.png');
  background-repeat: no-repeat;
  background-position: 95% 50%;
}

.nowrap {
  white-space: nowrap;
}

.whitebg {
  background-color: #fff;
}

a {
  color: inherit;
  text-decoration: none;
}

a:hover {
  color: #ff9225;
  text-decoration: none;
}

.row.baseline-aligned {
  align-items: baseline;
}

.half-width {
  width: 50%;
}

.one-fourth-width {
  width: 25%;
}

.one-third-width {
  width: 25%;
}

.btn {
  border-radius: 10px;
}

.btn-primary {
  background-color: #2684fe;
}

.clickable {
  cursor: pointer;
}

.category-tree li {
  list-style-type: none;
}

.simple-2-column-grid {
  display: grid;
  grid-template-columns: 330px auto;
}

.subtle-bottom-border {
  padding-bottom: 4px;
  border-bottom: 1px solid #cee2fd;
}

.pagy-nav.pagination {
  margin-top: 20px;
  margin-bottom: 20px;
}

.creatyea-padding {
  padding-left: 100px;
  padding-right: 100px;
}

.creatyea-padding .container-fluid,
.creatyea-padding.container-fluid {
  padding: 0px;
}

.custom-navbar-tablet-mobile-dropdown {
  display: block;
  position: absolute;
  top: 77px;
  right: 25px;
  width: 90vw;
  padding-block: 30px;
  min-height: 100px;
  background: white;
  border: 1px solid #cee2fd;
  border-radius: 10px;
  color: black;
}

.custom-navbar-tablet-mobile-dropdown .custom-dropdown-item.wrapper .btn {
  height: 45px;
}

.custom-navbar-tablet-mobile-dropdown.hide {
  display: none;
}

.custom-navbar-tablet-mobile-dropdown-toggle {
  display: none;
}

.custom-navbar-tablet-mobile-dropdown-toggle.close-button {
  font-size: 24px;
  font-weight: 300;
  margin-top: 0px;
}

.custom-navbar-tablet-mobile-dropdown .custom-dropdown-item {
  margin-top: 10px;
  display: block;
}

.custom-navbar-tablet-mobile-dropdown a {
  color: black !important;
  font-weight: 500;
  font-size: 18px;
  line-height: 28px;
  text-align: center;
}

.custom-navbar {
  background-color: #2684fe;
  color: white !important;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 4999;
  width: 100vw;
  max-height: 140px;
}

.modal-dialog {
  max-width: 700px;
}
.modal {
  z-index: 9999;
}
.modal-backdrop {
  z-index: 9998;
}

.custom-navbar header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.custom-navbar-search {
  display: flex;
  flex-direction: row;
  align-items: center;
  flex-grow: 1;
  margin-left: 40px;
  margin-right: 16px;
  max-width: 1164px;
}

.custom-navbar-search button[type='submit'] {
  border: 0px;
  border-radius: 0px 5px 5px 0px;
  border-radius: 0px 10px 10px 0px;
  width: 67px;
  height: 45px;
  font-size: 27px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.custom-navbar-tablet-mobile-dropdown .custom-navbar-search-mobile {
  display: none;
  flex-direction: row;
  align-items: center;
  flex-grow: 1;
  max-width: 80vw;
  margin-inline: auto;
}

.custom-navbar-search-mobile input {
  flex-grow: 1;
  height: 45px;
  width: calc(100% - 67px);
  border: 0px;
  border: 1px solid #cee2fd;
  border-radius: 10px 0px 0px 10px;
  padding-left: 15px;
  padding-right: 15px;
}

.custom-navbar-search-mobile button {
  border: 0px;
  border-radius: 0px 10px 10px 0px;
  width: 67px;
  height: 45px;
  font-size: 27px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.custom-navbar-cart {
  display: flex;
  flex-direction: column;
  margin-left: 1rem;
  margin-right: 1rem;
  max-width: 35px;
}

.custom-navbar-cart img {
  margin-bottom: 10px;
}

.custom-navbar-cart .cart-item-count {
  margin-bottom: -10px;
  margin-left: 3px;
  width: 33px;
  text-align: center;
  color: #ff9225;
  font-size: 12px;
  font-weight: 600;
}

.custom-navbar-dropdown-toggle {
  margin-left: 1rem;
  margin-right: 1rem;
}

.custom-navbar-brand img {
  width: 196px;
  height: auto;
}

.custom-navbar header {
  background-color: #011936;
  height: 90px;
}

.custom-navbar-menu {
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 50px;
  font-size: 18px;
  line-height: 28px;
  font-weight: 500;
}

.custom-navbar-item.dropdown .dropdown-toggle {
  font-weight: 600;
}

.custom-navbar-menu * {
  flex-grow: 1;
}

.custom-navbar-menu .spacer {
  flex-grow: 100;
}

.custom-navbar-menu a {
  color: white !important;
}

.custom-navbar-menu a.right-side {
  margin-left: 55px;
}

.custom-navbar-menu a.left-side {
  margin-right: 55px;
}

.custom-navbar-item.dropdown .dropdown-toggle {
  background-color: rgba(0, 0, 0, 0);
  border: 0px;
  cursor: pointer;
  color: white;
}

.custom-navbar-item.dropdown .dropdown-menu .dropdown-item {
  color: black !important;
}

.breadcrumb {
  color: #6d6d6d;
  padding-left: 0px;
  padding-right: 0px;
  background-color: rgba(0, 0, 0, 0);
  padding-top: 20px;
  padding-bottom: 12px;
  margin-bottom: 16px;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.breadcrumb a {
  color: #000;
}

.breadcrumb *:not(button svg) {
  margin-right: 27px;
}

.breadcrumb .svg-inline--fa {
  margin-top: 5px;
}

.cart.sidebar:nth-child(1) {
  margin-right: 30px;
}
.cart.sidebar:nth-child(2) {
  margin-left: 30px;
}

.with-top-clearance-margin {
  margin-top: 140px;
}

.category-image-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin-block: 1rem;
}

.customer-main-content {
  padding-bottom: 50px;
}

.page-content {
  width: 100%;
  display: flex;
  flex-direction: row;
}

.content-body,
.sidebar {
  flex-grow: 1;
}

#scroll-to-top-button {
  background-color: #2684fe;
  border: 0;
  border-radius: 10px;
  color: white;
  cursor: pointer;
  font-size: 16px;
  line-height: 48px;
  width: 48px;
  position: fixed;
  z-index: 500;
  bottom: 120px;
  right: 30px;
  opacity: 0;
  transition: all 0.2s ease-in-out;
}

#scroll-to-top-button.show-button {
  opacity: 1;
}

@media screen and (max-width: 1000px) {
  .with-top-clearance-margin {
    margin-top: 90px;
  }
  .custom-navbar-tablet-mobile-dropdown-toggle {
    display: inline-block;
  }
  .custom-navbar-item.custom-dropdown {
    display: none;
  }
  .custom-navbar-menu {
    display: none;
  }
}

/* @media screen and (max-width: 998px) {
} */

@media screen and (max-width: 890px) {
  .custom-navbar-search {
    display: none;
  }
  .custom-navbar-search-mobile {
    display: flex !important;
    flex-direction: row !important;
    align-items: center !important;
    flex-grow: 1 !important;
    max-width: 80vw !important;
    margin-inline: auto !important;
  }
}

@media screen and (max-width: 500px) {
  .featured-categories-section .body-section {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-row: auto;
    grid-row-gap: 10px;
    grid-column-gap: 10px;
    justify-content: space-evenly;
  }

  .creatyea-padding {
    padding-inline: 20px;
  }
  /* .heading-row.first-heading .cart-item-image {
    flex-basis: 80%;
  } */
}

@media (max-width: 640px) {
  .table-responsive {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
    -ms-overflow-style: -ms-autohiding-scrollbar;
  }
  .table-responsive > .table {
    margin-bottom: 0;
  }
}

.reach-out {
  font-size: 32px;
  line-height: 48px;
}

.custom-scroll::-webkit-scrollbar-thumb {
  background-color: #3f4751;
  border-radius: 20px;
  -webkit-border-radius: 20px;
  -moz-border-radius: 20px;
  -ms-border-radius: 20px;
  -o-border-radius: 20px;
}

.custom-scroll::-webkit-scrollbar-track {
  background-color: #f1f1f1;
}

.custom-scroll::-webkit-scrollbar {
  width: 5px;
  height: 5px;
}

.custom-select {
  position: relative;
}

.custom-select select {
  appearance: none !important;
  width: 100%;
  background-color: #fff !important;
  border: 1px solid #caced1 !important;
  border-radius: 0.25rem !important;
  color: #000 !important;
  cursor: pointer !important;
  background-image: none !important;
  -webkit-border-radius: 0.25rem !important;
  -moz-border-radius: 0.25rem !important;
  -ms-border-radius: 0.25rem !important;
  -o-border-radius: 0.25rem !important;
}

.custom-select::before,
.custom-select::after {
  --size: 0.3rem;
  content: '' !important;
  position: absolute !important;
  right: 1rem !important;
  pointer-events: none !important;
}

.custom-select::before {
  border-left: var(--size) solid transparent !important;
  border-right: var(--size) solid transparent !important;
  border-bottom: var(--size) solid black !important;
  top: 40% !important;
}

.custom-select::after {
  border-left: var(--size) solid transparent !important;
  border-right: var(--size) solid transparent !important;
  border-top: var(--size) solid black !important;
  top: 55%;
}

/* style table rows to make even has a different color */
table tbody tr:nth-of-type(odd) {
  background-color: rgba(128, 128, 128, 0.078) !important;
}

.graph-table {
  max-width: 426px;
  width: 100%;
  display: table;
  border-collapse: separate;
  border-spacing: 0;
  border-bottom: 1px solid #444444;
  border-right: 1px solid !important;
  border-left: 1px solid !important;
  table-layout: fixed;
}

.graph-table .tr {
  display: table-row;
}

#VLF-band {
  width: 20%;
}

#LF-band {
  width: 19.25%;
}
#MF-band {
  width: 13.75%;
}
#HF-band {
  width: 13%;
}
#VHF-band {
  width: 10.25%;
}
#UHF-band {
  width: 9.25%;
}
#SHF-band {
  width: 9%;
}

.graph-table .band-label {
  vertical-align: middle;
  text-align: center;
  font-family: Arial;
  font-style: normal;
  font-weight: bold;
  font-size: 12px;
  line-height: 14px;
  text-transform: uppercase;
  color: #cccccc;
}
.graph-table .band-graph {
  display: table-cell;
  vertical-align: middle;
  text-align: center;
  border-right: 1px solid #cccccc;
}

.highlight {
  stroke: #2684fe;
}

.PB-range-slider {
  -webkit-appearance: none;
  width: 100%;
  height: 4px;
  border-radius: 5px;
  background: #d5dbe1;
  outline: none;
  opacity: 0.7;
  -webkit-transition: 0.2s;
  transition: opacity 0.2s;
}

.PB-range-slider::-webkit-slider-thumb {
  -webkit-appearance: none;
  appearance: none;
  width: 15px;
  height: 15px;
  border-radius: 50%;
  background-color: #000000;
  cursor: pointer;
  transition: 0.3s ease-in-out;
}

.PB-range-slider::-webkit-slider-thumb:hover {
  box-shadow: 0px 0px 0px 8px rgba(0, 0, 0, 0.16);
  transition: 0.3s ease-in-out;
}

.PB-range-slider::-moz-range-thumb {
  width: 15px;
  height: 15px;
  border-radius: 50%;
  background-color: #000000;
  cursor: pointer;
}

.PB-range-slider-div {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 1rem;
  padding: 1rem;
  border-radius: 6px;
  border: 1px solid #c6cace;
  width: 100%;
  box-shadow: 0px 1px 2px 0px #1f1e241f;
}

.PB-range-slidervalue {
  font-weight: 600;
}